import { QueryResource } from "core-app/features/hal/resources/query-resource";
import { Injectable } from "@angular/core";
import { WorkPackageViewHierarchies } from "core-app/features/work-packages/routing/wp-view-base/view-services/wp-table-hierarchies";
import { WorkPackageQueryStateService } from "./wp-view-base.service";
import { BehaviorSubject, Observable, Subject } from "rxjs";

@Injectable()
export class WorkPackageViewHierarchiesService extends WorkPackageQueryStateService<WorkPackageViewHierarchies> {
  private storageWpHierarchyKeys = window.OpenProject.storageWpHierarchyKeys;
  private storageWpCurrentPage = window.OpenProject.storageWpCurrentPage;
  private states: Array<WorkPackageViewHierarchies>;
  private isActualKeys: Array<string>;
  private page: number;

  private flagSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private flagDisSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public flag$: Observable<boolean> = this.flagSubject.asObservable();
  public flagDis$: Observable<boolean> = this.flagDisSubject.asObservable();

  public valueFromQuery(query: QueryResource): WorkPackageViewHierarchies {
    const value = this.getStateStored;
    value.isVisible = query.showHierarchies;
    const { current } = this;
    // Take over current collapsed values which are not yet saved
    if (current) {
      this.update(value);
      this.getUpdate(value.collapsed);
    }
    this.states = this.getStates;
    this.flagDisSubject.next(!value.isVisible);
    return value;
  }

  public hasChanged(query: QueryResource) {
    return query.showHierarchies !== this.isEnabled;
  }

  public applyToQuery(query: QueryResource) {
    query.showHierarchies = this.isEnabled;
    // We need to visibly load the ancestors when the mode is activated.
    return this.isEnabled;
  }

  /**
   * Return whether the current hierarchy mode is active
   */
  public get isEnabled(): boolean {
    return !!(this.current && this.current.isVisible);
  }

  public setEnabled(active = true) {
    const state = { ...this.current, isVisible: active, last: null };
    this.update(state);
    this.flagDisSubject.next(!active);
  }

  /**
   * Toggle the hierarchy state
   */
  public toggleState(): boolean {
    this.setEnabled(!this.isEnabled);
    return this.isEnabled;
  }

  /**
   * Return whether the given wp ID is collapsed.
   */
  public collapsed(wpId: string): boolean {
    return this.current.collapsed[wpId];
  }

  /**
   * Collapse the hierarchy for this work package
   * Сверните иерархию для этого рабочего пакета
   */
  public collapse(wpId: string): void {
    this.setState(wpId, true);
  }

  /**
   * Expand the hierarchy for this work package
   */
  public expand(wpId: string): void {
    this.setState(wpId, false);
  }

  /**
   * Toggle the hierarchy state
   */
  public toggle(wpId: string): void {
    this.setState(wpId, !this.collapsed(wpId));
  }

  /**
   * Expand <-> Collapse
   */
  public allToggle(flag: boolean): void {
    const state = this.current.collapsed;
    const listCollapseId: string[] = [];
    const listExpandId: string[] = [];

    Object.entries(state).forEach((entry) => {
      const [key, value] = entry;
      value ? listCollapseId.push(key) : listExpandId.push(key);
    });
    flag ? this.setStateAll(listCollapseId, false) : this.setStateAll(listExpandId, true);
    this.flagSubject.next(flag);
  }

  /**
   * Set current page local storage
   */
  public set currentPage(page: number) {
    this.page = page;
  }

  /**
   * Get current page local storage
   */
  public get currentPage(): number {
    if (this.page != undefined) {
      return this.page - 1;
    }
    const value = window.OpenProject.guardedLocalStorage(this.storageWpCurrentPage) as string;
    if (value !== undefined) {
      return parseInt(value, 10) - 1; //offset in array
    }
    return 0;
  }

  /**
   * Set actual keys
   */
  public setActualKeys(keys: string[]) {
    const state = this.current;
    state.collapsed = this.compareCollapsed(keys, state.collapsed);
    this.update(state);
    this.setStateLocalStorage(state);
  }

  /**
   * Get actual keys
   */
  public get actualKeys() {
    return this.isActualKeys;
  }

  /**
   * Get current selection state.
   */
  public get current(): WorkPackageViewHierarchies {
    const state = this.lastUpdatedState.value;
    if (state === undefined) {
      return this.getStateStored; // the go local store
    }
    if (_.isEqual(state.collapsed, {})) {
      state.collapsed = this.getStateStored.collapsed; // state.collapsed === {} the go local store
    }
    return state;
  }

  /**
   * Set the collapse/expand state of the given work package id.
   */
  private setState(wpId: string, isCollapsed: boolean): void {
    const state = { ...this.current, last: wpId };
    state.collapsed[wpId] = isCollapsed;
    this.update(state);

    this.getUpdate(state.collapsed);
    this.setStateLocalStorage(state);
  }

  /**
   * Set all the collapse/expand state of the given work package id.
   */
  private setStateAll(arrayWpId: string[], isCollapsed: boolean): void {
    const index = arrayWpId.length - 1;
    const state = { ...this.current, last: arrayWpId[index] };
    arrayWpId.forEach((key) => {
      state.collapsed[key] = isCollapsed;
    });
    this.update(state);
    this.setStateLocalStorage(state);
  }

  private compareCollapsed(keysActual: string[], objStored: { [workPackageId: string]: boolean }) {
    const newCollapsed: { [workPackageId: string]: boolean } = {};
    const collapsed = Object.keys(objStored);
    const pushKeys = keysActual.filter((item) => !collapsed.includes(item));
    const commonKeys = keysActual.filter((item) => collapsed.includes(item));

    pushKeys.forEach((key) => {
      newCollapsed[key] = false;
    });
    commonKeys.forEach((key) => {
      newCollapsed[key] = objStored[key];
    });
    return newCollapsed;
  }

  /**
   * Set state local storage
   */
  private setStateLocalStorage(state: any): void {
    this.states[this.currentPage] = state;
    window.OpenProject.guardedLocalStorage(this.storageWpHierarchyKeys, JSON.stringify(this.states));
  }

  private getUpdate(state: { [workPackageId: string]: boolean }) {
    const values = Object.values(state);
    const flagOn = values.includes(true);
    this.flagSubject.next(!flagOn);
  }

  private get getStateStored(): WorkPackageViewHierarchies {
    const localStates = window.OpenProject.guardedLocalStorage(this.storageWpHierarchyKeys);
    if (localStates != undefined) {
      const states = JSON.parse(localStates);
      return states[this.currentPage] != undefined ? states[this.currentPage] : this.initialState;
    }
    return this.initialState;
  }

  private get getStates(): Array<WorkPackageViewHierarchies> {
    const localStates = window.OpenProject.guardedLocalStorage(this.storageWpHierarchyKeys);
    if (localStates != undefined) {
      const states = JSON.parse(localStates);
      return states != undefined ? states : [];
    }
    return [];
  }

  private get initialState(): WorkPackageViewHierarchies {
    return new WorkPackageViewHierarchies(false);
  }
}
