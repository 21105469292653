<div class="switch">
  <input id="cmn-toggle-work" class="cmn-toggle cmn-toggle-round" type="checkbox" [disabled]="isDisabled"
    [(ngModel)]="isChecked" />
  <svg class="is_checked" viewBox="0 0 1600 1600">
    <path
      d="M492.8 434.4c-7.4 1.6-14.4 6-19 12.1-7 9.2-6.8 6.6-6.8 87.9v73.1l2.9 6c3.5 7.5 9 13 16.1 16.4l5.5 2.6 54.2.3 54.3.2.2 220.8.3 220.7 2.6 5.6c3.4 7.2 9.6 13.4 16.8 16.8l5.6 2.6 87.2.3 87.1.2.4 20.8c.3 19.8.4 20.9 2.9 26.2 3.3 7 9.5 13.2 16.7 16.7l5.7 2.8h282l6.7-3.3c7.3-3.6 11.9-8.2 15.8-15.9l2.5-4.8.3-74.2c.2-74 .2-74.1-1.9-79.9-2.8-7.4-10.2-15.4-17.2-18.5l-5.2-2.4-139.9-.3c-156.3-.3-146-.7-155.5 6.8-11.1 8.8-13.1 14.8-13.1 40.2v18.8H667V833h132.9l.3 20.7c.3 20.1.4 21 2.9 26.4 3.4 7.2 9.6 13.4 16.8 16.8l5.6 2.6h283l5.8-3c7.3-3.7 14-10.9 16.6-18.1 2-5.3 2.1-7.3 2.1-78.4 0-70.9-.1-73.1-2-78.4-2.4-6.4-8.6-13.7-14.3-17-2.3-1.4-6.9-3-10.2-3.6-4.3-.8-44.6-1-143.5-.8l-137.5.3-6.3 2.9c-7.3 3.4-12.4 8.6-16.1 16.5-2.5 5.4-2.6 6.4-2.9 26.3l-.3 20.8H667V633h53c50.9 0 53.1-.1 58.4-2.1 7.2-2.6 14.4-9.3 18.1-16.6l3-5.8v-150l-2.6-5.5c-2.9-6.2-9.5-13.2-14.9-16-2-1-6.2-2.3-9.5-2.9-8.8-1.6-272-1.4-279.7.3zM733 533.5V567H533v-67h200v33.5zM1067 800v33H867v-66h200v33zm-.2 266.7.2 33.3H867v-67l99.8.2 99.7.3.3 33.2z" />
    <path fill-opacity=".34"
      d="M466.5 516.5c0 27.5.1 38.6.2 24.7.2-13.9.2-36.4 0-50-.1-13.6-.2-2.2-.2 25.3zm-.1 67c0 9.3.2 13.3.3 8.8.2-4.5.2-12.2 0-17-.1-4.8-.3-1.2-.3 8.2zm600 216.5c0 18.4.2 26 .3 16.7.2-9.2.2-24.2 0-33.5-.1-9.2-.3-1.6-.3 16.8zm0 250c0 9.1.2 12.8.3 8.2.2-4.5.2-11.9 0-16.5-.1-4.5-.3-.8-.3 8.3zm0 28.5c0 6.6.1 9.2.3 5.7.2-3.4.2-8.8 0-12-.2-3.1-.3-.3-.3 6.3zm-.1 17c0 2.7.2 3.8.4 2.2.2-1.5.2-3.7 0-5-.2-1.2-.4 0-.4 2.8z" />
    <path fill-opacity=".33"
      d="M533.4 533c0 18.4.2 26 .3 16.7.2-9.2.2-24.2 0-33.5-.1-9.2-.3-1.6-.3 16.8zm600 217c0 9.1.2 12.8.3 8.2.2-4.5.2-11.9 0-16.5-.1-4.5-.3-.8-.3 8.3zm0 50c0 18.4.2 26 .3 16.7.2-9.2.2-24.2 0-33.5-.1-9.2-.3-1.6-.3 16.8zm0 50c0 9.1.2 12.8.3 8.2.2-4.5.2-11.9 0-16.5-.1-4.5-.3-.8-.3 8.3zm0 166.5c0 9.3.2 13.3.3 8.8.2-4.5.2-12.2 0-17-.1-4.8-.3-1.2-.3 8.2zm0 33.5c0 9.1.2 12.8.3 8.2.2-4.5.2-11.9 0-16.5-.1-4.5-.3-.8-.3 8.3zm-.1 23c0 3.6.2 5 .4 3.2.2-1.7.2-4.7 0-6.5-.2-1.7-.4-.3-.4 3.3zm0 12c0 3 .2 4.3.4 2.7.2-1.5.2-3.9 0-5.5-.2-1.5-.4-.2-.4 2.8zm-.1 9c0 1.9.2 2.7.5 1.7.2-.9.2-2.5 0-3.5-.3-.9-.5-.1-.5 1.8zm.2 22.5c0 9.3.2 13.3.3 8.8.2-4.5.2-12.2 0-17-.1-4.8-.3-1.2-.3 8.2z" />
    <path fill-opacity=".25"
      d="M583.8 566.7c27.7.2 72.7.2 100 0 27.3-.1 4.7-.2-50.3-.2s-77.4.1-49.7.2zm-59 67c13.8.2 36.6.2 50.5 0 13.8-.1 2.5-.2-25.3-.2s-39.1.1-25.2.2zm167 0c13.9.2 36.4.2 50 0 13.6-.1 2.2-.2-25.3-.2s-38.6.1-24.7.2zm8.5 133c18.6.2 48.8.2 67 0 18.3-.1 3.1-.2-33.8-.2-36.8 0-51.8.1-33.2.2zm0 67c18.6.2 48.8.2 67 0 18.3-.1 3.1-.2-33.8-.2-36.8 0-51.8.1-33.2.2zm200 133c36.7.2 96.7.2 133.5 0 36.7-.1 6.6-.2-66.8-.2-73.4 0-103.5.1-66.7.2zm16.5 67c27.7.2 72.7.2 100 0 27.3-.1 4.7-.2-50.3-.2s-77.4.1-49.7.2z" />
  </svg>
  <svg class="is_unchecked" viewBox="0 0 1600 1600">
    <path
      d="M496.5 608.7c-8.7 2-15 6.5-18.8 13.7-3 5.7-3 16.5 0 22.1 2.8 5.2 6.7 8.9 12.3 11.4 4.5 2.1 4.8 2.1 310 2.1s305.5 0 310-2.1c5.6-2.5 9.5-6.2 12.3-11.4 3-5.6 3-16.4 0-22-2.7-5.2-7.7-9.9-12.8-12.2-3.8-1.7-17.5-1.8-307.5-1.9-166.9-.1-304.4 0-305.5.3zm-4.2 167.6c-17.7 6.6-22.8 27.7-9.9 41 8.2 8.5-23.8 7.7 317.6 7.7 338.7 0 309.4.6 316.9-6.9 5.5-5.5 7.6-10.5 7.6-18.1 0-11.1-6.1-19.7-16.8-23.7-2.4-.9-77.1-1.1-307.7-1.1s-305.3.2-307.7 1.1zM490 944.1c-8.8 4-13.8 11.1-14.7 20.9-.9 9.9 5.6 20.3 15.6 24.8 3.3 1.6 27.1 1.7 308.6 1.7 291.5 0 305.2-.1 309.7-1.8 9.6-3.7 16.6-15 15.5-25.1-1-9.5-6-16.6-14.7-20.5-4.5-2.1-4.8-2.1-310-2.1s-305.5 0-310 2.1z" />
    <path
      d="M649.7 658.7c82.7.2 217.9.2 300.5 0 82.7-.1 15.1-.2-150.2-.2-165.3 0-232.9.1-150.3.2zm0 283c82.7.2 217.9.2 300.5 0 82.7-.1 15.1-.2-150.2-.2-165.3 0-232.9.1-150.3.2z" />
  </svg>
</div>